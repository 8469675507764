import React, { CSSProperties } from 'react'
import { PageComponentProps, PageName } from './App'
import { PressableDiv, palette, secondaryButtonStyle } from './Utils'
import { videosData, VideoData, videosDataByName, videoPathFromName, mapOverlayPathFromName } from './videos'
import { popPage } from './historyUtils'


export const OLD_VideoList: React.FC<{videoHovered: (name: string | null) => void, videoSelected: (name: string) => void, entryHeight: string, fontSize: string, containerStyle?: CSSProperties}> = props => {
    const [hoveredVideo, setHoveredVideo] = React.useState<string | null>(null)

    const getEntryStyle = (enabled: boolean, hovered: boolean): CSSProperties => ({
        textAlign: 'left',
        border: '0 none',
        padding: 0,
        backgroundColor: 'transparent',
        fontWeight: 'bold',

        height: props.entryHeight,
        fontSize: props.fontSize,
        color: enabled ? (hovered ? '#b03800' : 'black') : 'grey',
        transition: '0.2s',
    })
    return (
        <div style={{position: 'absolute', inset: 0, display: 'flex', flexDirection: 'column', ...props.containerStyle}}>
            {videosData.map(d =>
                <button
                    onClick={() => props.videoSelected(d.name)}
                    onMouseEnter={() => {
                        setHoveredVideo(d.name)
                        props.videoHovered(d.name)
                    }}
                    onMouseLeave={() => {
                        setHoveredVideo(null)
                        props.videoHovered(null)
                    }}
                    disabled={d.file === null}
                    key={d.name}
                    style={getEntryStyle(d.file !== null, d.name === hoveredVideo)}
                >
                    {d.name.toUpperCase()}
                </button>)}
        </div>
    )
}

export const VideoList: React.FC<{onHover?: (videoName: string | null) => void, onChosen: (videoName: string) => void, fontSize: string, gap: string}> = props => {
    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: props.gap}}>
            {videosData.filter(d => d.file !== null).map((d, i) =>
                <PressableDiv
                    key={i}
                    onMouseEnter={() => props.onHover?.(d.name)}
                    onMouseLeave={() => props.onHover?.(null)}
                    onClick={() => {
                        if (d.file !== null) {
                            props.onChosen(d.name)
                        }
                    }}
                    style={{fontSize: props.fontSize, display: 'flex', flexDirection: 'column', lineHeight: '100%'}}
                    hoveredStyle={{color: palette.hovered}}
                >
                    <div>
                        {d.name.toUpperCase()}
                    </div>
                    { d.subtitle &&
                        <div style={{color: palette.subtitle, fontSize: 'calc(' + props.fontSize + ' * 0.7)'}}>
                            {d.subtitle.toUpperCase()}
                        </div>
                    }
                </PressableDiv>
            )}
        </div>
    )
}

export const NorciaMap: React.FC<{selectedName: string | null, containerStyle?: CSSProperties, fit: 'width' | 'height'}> = props => {
    const fitStyle: CSSProperties = props.fit === 'width' ? {width: '100%'} : {height: '100%'}
    const posStyle: React.CSSProperties = {
        position: 'absolute',
        inset: 0,
        objectFit: 'contain',
        ...fitStyle,
    }
    return (
        <div style={{position: 'relative', aspectRatio: '1 / 1', ...props.containerStyle}}>
            <img alt='Mappa' src='Minimap.svg' style={{objectFit: 'contain', ...fitStyle}} />
            {props.selectedName && <img alt='' src={mapOverlayPathFromName(props.selectedName)} style={{...posStyle, opacity: 0.7, filter: 'sepia(100%) hue-rotate(-60deg) saturate(3000%) blur(calc(min(0.5vw, 0.5vh)))'}} />}
        </div>
    )
}

export const VideoPreview: React.FC<{videoName: string | null, style?: CSSProperties, fontSize: string, onClick: () => void}> = props => {
    const [hovered, setHovered] = React.useState(false)

    let path = props.videoName === null ? '' : videoPathFromName(props.videoName)
    return (
        <PressableDiv style={{display: 'flex', position: 'relative', width: '100%'}} onClick={() => props.onClick()} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
            <video src={path} muted autoPlay loop playsInline style={{objectFit: 'contain', width: '100%', ...props.style}} />
            <div style={{position: 'absolute', inset: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: 'white', fontSize: props.fontSize, backgroundColor: '#000000a0', transition: '0.2s', opacity: hovered ? 1 : 0}}>
                <img src="play.svg" alt="play" style={{width: 'calc(' + props.fontSize + ' * 2)'}} />
                Guarda il video
            </div>
        </PressableDiv>
    )
}

export const OLD_VideosMenuDesktop: React.FC<PageComponentProps> = props => {
    const [hoveredVideo, setHoveredVideo] = React.useState<string | null>(null)
    const [chosenVideo, setChosenVideo] = React.useState<string | null>(null)

    const chosenVideoPadding = '5vw'

    return <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100vw', backgroundImage: 'url(/background.jpg)', padding: '3vw', gap: '1.5vw', boxSizing: 'border-box'}}>
        <div style={{position: 'relative', display: 'flex', flex: 0.2, height: '100%'}}>
            <OLD_VideoList
                videoHovered={name => {
                    setHoveredVideo(name)
                }}
                videoSelected={name => {
                    setHoveredVideo(null)
                    setChosenVideo(name)
                }}
                entryHeight='2.5vw'
                fontSize='1.25vw'
            />
        </div>
        <div style={{position: 'relative', display: 'flex', flex: 0.50, height: '100%'}}>
            <NorciaMap selectedName={hoveredVideo} fit='height' />
        </div>
        <div style={{position: 'relative', display: 'flex', flexDirection: 'column', flex: 0.30, height: '100%'}}>
            <VideoPreview videoName={hoveredVideo} style={{margin: 'auto'}} fontSize='2vh' onClick={() => {}} />
            <button style={{...secondaryButtonStyle, marginTop: 'auto', width: '100%'}} onClick={() => props.pushPage({name: PageName.main, args: null})}>Indietro</button>
        </div>

        <div style={{display: chosenVideo === null ? 'none' : 'flex', position: 'absolute', inset: 0, padding: chosenVideoPadding, boxSizing: 'border-box', flexDirection: 'column', backgroundColor: '#000000b0'}}>
            <button style={{position: 'absolute', width: chosenVideoPadding, height: chosenVideoPadding, top: 0, right: 0, fontSize: '4vw', fontWeight: 'bold', alignSelf: 'flex-end', backgroundColor: 'transparent', border: 'none', padding: 0, cursor: 'pointer'}} onClick={() => setChosenVideo(null)}>
                <img src="x_white.svg" width={'100%'} style={{boxSizing: 'border-box', padding: '1vw', opacity: 0.8}} />
            </button>
            <video src={chosenVideo === null ? '' : videoPathFromName(chosenVideo)} style={{objectFit: 'contain', maxWidth: '100%', maxHeight: '100%'}} autoPlay />
        </div>
    </div>
}

export const VideosMenuDesktop: React.FC<PageComponentProps> = props => {
    const [hoveredVideo, setHoveredVideo] = React.useState<string | null>(null)

    return <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
        <div style={{display: 'flex', position: 'relative', flexDirection: 'row', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'flex-start', gap: 0}}>
            <NorciaMap fit='height' selectedName={hoveredVideo} containerStyle={{height: '75vh', marginRight: 'auto'}} />
            <VideoList fontSize={'2.1vh'} gap={'0.75vh'} onHover={videoName => setHoveredVideo(videoName)} onChosen={videoName => props.pushPage({name: PageName.watch, args: {videoName: videoName}})} />
        </div>
    </div>
}

export const OLD_VideosMenuMobile: React.FC<PageComponentProps> = props => {
    return (
        <>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'center', width: '100%', padding: '3vh', gap: '1.5vh', boxSizing: 'border-box'}}>
                <OLD_VideoList
                    videoHovered={_ => {}}
                    videoSelected={name => {
                        props.pushPage({name: PageName.map, args: {videoName: name}})
                    }}
                    entryHeight='6vh'
                    fontSize='3vh'
                    containerStyle={{position: 'relative'}}
                />
            </div>
            <div style={{position: 'absolute', inset: 0, backgroundImage: 'url(/background.jpg)', zIndex: -10}} />
        </>
    )
}

export const VideosMenuMobile: React.FC<PageComponentProps> = props => {
    return (
        <div style={{display: 'flex', position: 'relative', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'flex-start', gap: '5vw', boxSizing: 'border-box', padding: '6vw'}}>
            <img src="Logo_Norcia.png" alt="Norcia Live Stones" style={{width: '25vw', cursor: 'pointer'}} onClick={() => {}} />
            <VideoList fontSize={'5vw'} gap={'5vw'} onChosen={videoName => props.pushPage({name: PageName.map, args: {videoName: videoName}})} />
        </div>
    )
}

export const MapMenuMobile: React.FC<PageComponentProps> = props => {
    const {videoName} = props.args
    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', padding: '6vw', gap: '2vw', boxSizing: 'border-box'}}>
            <NorciaMap selectedName={videoName} containerStyle={{width: '90%'}} fit='width' />
            <PressableDiv
                style={{backgroundColor: palette.primary, color: palette.primaryOpposite, width: '100%', fontSize: '5vw', padding: '2vw', justifyContent: 'center', boxShadow: '1vw 1vw 2vw grey', marginTop: '4vw', marginBottom: '4vw'}}
                onClick={() => props.pushPage({name: PageName.watch, args: {videoName: videoName}})}
            >
                Guarda il video
            </PressableDiv>
            <span style={{fontSize: '5vw'}}>{props.args.videoName.toUpperCase()}</span>
            <VideoPreview videoName={videoName} fontSize='4vw' onClick={() => props.pushPage({name: PageName.watch, args: {videoName: videoName}})} />
        </div>
    )
}

// non usato (saltato nella navigazione desktop)
export const MapMenuDesktop: React.FC<PageComponentProps> = props => {
    const {videoName} = props.args
    return (
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', gap: '2vh', boxSizing: 'border-box'}}>
            <NorciaMap selectedName={videoName} fit='height' containerStyle={{height: '60vh', alignItems: 'center', justifyContent: 'center'}} />
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 'auto', width: '80vh'}}>
                <span style={{fontSize: '5vh'}}>{props.args.videoName.toUpperCase()}</span>
                <VideoPreview videoName={videoName} fontSize='4vh' onClick={() => props.pushPage({name: PageName.watch, args: {videoName: videoName}})} />
            </div>
        </div>
    )
}

export const VideoWithAudio: React.FC<{style: CSSProperties, src: string, onEnded: () => void}> = props => {
    const [showPlayButton, setShowPlayButton] = React.useState(false)
    const videoRef = React.useRef<HTMLVideoElement>(null)

    React.useEffect(() => {
        videoRef.current?.play()
            .then(() => {
                // tutto ok
            })
            .catch(() => {
                setShowPlayButton(true)
            })
    }, [])

    return (
        <div style={{position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', ...props.style}}>
            <video ref={videoRef} src={props.src} style={{objectFit: 'contain', maxWidth: '100%', maxHeight: '100%'}} controls playsInline onEnded={props.onEnded} />
            { showPlayButton &&
                <PressableDiv style={{position: 'absolute', inset: 0, backgroundColor: showPlayButton ? '#000000aa' : undefined, display: 'flex', alignItems: 'center', justifyContent: 'center'}} onClick={() => {
                    videoRef.current?.play()
                    setShowPlayButton(false)
                }}>
                    <img src="play.svg" style={{width: '40%', height: '40%'}} />
                </PressableDiv>
            }
        </div>
    )
}

export const OLD_WatchPageDesktop: React.FC<PageComponentProps> = props => {
    const {videoName} = props.args
    const chosenVideoPadding = '8vh'
    return (
        <div style={{display: 'flex', position: 'absolute', inset: 0, width: '100%', height: '100%', padding: chosenVideoPadding, boxSizing: 'border-box', flexDirection: 'column', backgroundColor: '#000000c0'}}>
            <button
                style={{position: 'absolute', width: chosenVideoPadding, height: chosenVideoPadding, top: 0, right: 0, alignSelf: 'flex-end', backgroundColor: 'transparent', border: 'none', padding: 0, cursor: 'pointer'}}
                onClick={() => popPage()}
            >
                <img src="x_white.svg" width={'100%'} style={{boxSizing: 'border-box', padding: '1vh', opacity: 0.8}} />
            </button>
            <VideoWithAudio src={videoPathFromName(videoName)} style={{objectFit: 'contain', maxWidth: '100%', maxHeight: '100%'}} onEnded={() => popPage()} />
        </div>
    )
}

export const WatchPageDesktop: React.FC<PageComponentProps> = props => {
    const {videoName} = props.args
    const chosenVideoPadding = '8vh'
    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
            <span style={{fontSize: '5vh'}}>{videoName.toUpperCase()}</span>
            <VideoWithAudio src={videoPathFromName(videoName)} style={{objectFit: 'contain', maxWidth: '100%', maxHeight: '80%'}} onEnded={() => popPage()} />
        </div>
    )
}

export const WatchPageMobile: React.FC<PageComponentProps> = props => {
    const {videoName} = props.args
    return (
        <VideoWithAudio src={videoPathFromName(videoName)} style={{objectFit: 'contain', maxWidth: '100%', maxHeight: '100%', backgroundColor: 'black'}} onEnded={() => popPage()} />
    )
}