import React from 'react'
import { PageComponentProps } from './App'
import { VideoWithAudio } from './VideosMenu'
import { popPage } from './historyUtils'


export type SimpleVideoPageArgs = {
    videoUrl: string,
}

export const SimpleVideoPage: React.FC<PageComponentProps> = props => {
    const videoPageArgs = props.args as SimpleVideoPageArgs
    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
            <VideoWithAudio src={videoPageArgs.videoUrl} style={{objectFit: 'contain', maxWidth: '100%', maxHeight: '100%'}} onEnded={() => popPage()} />
        </div>
    )
}