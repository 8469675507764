import React from 'react';
import './App.css';
import { MainMenuDesktop, MainMenuMobile } from './MainMenu';
import { PlatformSwitch } from './PlatformSwitch';
import { MapMenuDesktop, MapMenuMobile, VideosMenuDesktop, VideosMenuMobile, WatchPageDesktop, WatchPageMobile } from './VideosMenu';
import { SimpleVideoPage } from './TutorialScreen';


export enum PageName {
  main = 'main',
  videos = 'videos',
  map = 'map',
  watch = 'watch',
  tutorial = 'tutorial',
}

export type PageRouterEntry = {
  name: PageName,
  args: any,
}

export type PushPageType = (page: PageRouterEntry) => void
export type PageComponentProps = {args: any, pushPage: PushPageType}

function App() {
  const [pageStack, setPageStack] = React.useState<PageRouterEntry[]>([{
    name: PageName.main,
    args: null,
  }])
  const pageStackRef = React.useRef(pageStack)
  pageStackRef.current = pageStack

  const onPopState = () => {
    if (pageStackRef.current.length >= 2) {
      setPageStack(pageStackRef.current.slice(0, -1))
    }
  }

  React.useEffect(() => {
    window.addEventListener("popstate", onPopState)
  }, [])

  const pushPage = (pageEntry: PageRouterEntry) => {
    window.history.pushState(pageEntry, ''/*, "?" + page*/)
    setPageStack([...pageStack, pageEntry])
  }

  const currentPage = pageStack[pageStack.length - 1]
  console.log(currentPage)
  return (
    currentPage.name === PageName.main ? <PlatformSwitch noBack pushPage={pushPage} desktop={<MainMenuDesktop pushPage={pushPage} args={currentPage.args} />} mobile={<MainMenuMobile pushPage={pushPage} args={currentPage.args} />} /> :
    currentPage.name === PageName.videos ? <PlatformSwitch pushPage={pushPage} desktop={<VideosMenuDesktop pushPage={pushPage} args={currentPage.args} />} mobile={<VideosMenuMobile pushPage={pushPage} args={null} />} /> :
    currentPage.name === PageName.map ? <PlatformSwitch pushPage={pushPage} desktop={<MapMenuDesktop pushPage={pushPage} args={currentPage.args} />} mobile={<MapMenuMobile pushPage={pushPage} args={currentPage.args} />} /> :
    currentPage.name === PageName.watch ? <PlatformSwitch pushPage={pushPage} desktop={<WatchPageDesktop pushPage={pushPage} args={currentPage.args} />} mobile={<WatchPageMobile pushPage={pushPage} args={currentPage.args} />} /> :
    currentPage.name === PageName.tutorial ? <PlatformSwitch darkMode pushPage={pushPage} desktop={<SimpleVideoPage pushPage={pushPage} args={currentPage.args} />} mobile={<SimpleVideoPage pushPage={pushPage} args={currentPage.args} />} /> :
    null
  );
}

export default App;
