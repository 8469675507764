export type VideoData = {
    name: string,
    subtitle: string | null,
    file: string | null,
}

export const videosData: VideoData[] = [
    {
        name: "Welcome Stone",
        subtitle: "Benvenuti nel cantiere della comunicazione",
        file: "WELCOME_STONE_WEB.mp4"
    },
    {
        name: "Project Stone",
        subtitle: "Le linee guida del progetto",
        file: "ProjectStone_WEB.mp4"
    },
    {
        name: "Info Stone",
        subtitle: null,
        file: "norcia_cronoprogramma_HD_WEB.mp4"
    },
    {
        name: "Saint Scholastica Stone",
        subtitle: "LE FIGURE FEMMINILI DI SAN BENEDETTO",
        file: "ScholasticaStone_WEB.mp4"
    },
    {
        name: "Stone of the Stones",
        subtitle: "LE PIETRE RACCONTANO",
        file: "norcia_pillole_A_HD_WEB.mp4"
    },
    {
        name: "Reconstructors Stone",
        subtitle: "LA METODOLOGIA DELL'INTERVENTO DI RECUPERO",
        file: "norcia_intervista_consegna_WEB.mp4"
    },
    {
        name: "Documentary Stone",
        subtitle: "IL PERCORSO DI RECUPERO DELLA BASILICA DI SAN BENEDETTO",
        file: "DocumentaryStone_WEB.mp4"
    },
    {
        name: "Backwards Stone",
        subtitle: "CRONISTORIA DELLA BASILICA DI SAN BENEDETTO",
        file: "BackwardsStoneVideo_WEB.mp4"
    },
    {
        name: "Onward Stone",
        subtitle: "ENI SCUOLA - LA STORIA DI NORCIA",
        file: "OnwardStone_WEB.mp4"
    },
    {
        name: "Iconographic Stone",
        subtitle: "SAN BENEDETTO, IL RACCONTO PITTORICO",
        file: "SanBenedettoRacconto_WEB.mp4"
    },
    {
        name: "Social Stone",
        subtitle: "ENI SCUOLA - TERRITORIO, NATURA E TRADIZIONE",
        file: "1_TerritorioNorciaPerMe_WEB.mp4"
    },
    {
        name: "Knowledge Stone",
        subtitle: null,
        file: null
    },
    {
        name: "Benedectine Stone",
        subtitle: "SAN BENEDETTO, LA DOTTRINA E I MONASTERI",
        file: "san_benedetto_dottrina_e__monasteri_WEB.mp4"
    },
    {
        name: "Poem Stone",
        subtitle: "DANTE INCONTRA SAN BENEDETTO",
        file: "PoemStone_WEB.mp4"
    },
    {
        name: "Talking Stone",
        subtitle: null,
        file: null
    },
    {
        name: "International Stone",
        subtitle: null,
        file: null
    },
    {
        name: "Live Media Stone",
        subtitle: null,
        file: null
    },
]

export const videosDataByName = new Map(videosData.map(d => [d.name, d]))

export const videoPathFromName = (name: string): string => {
    if (!videosDataByName.has(name)) throw new Error()

    const data = videosDataByName.get(name)!
    if (data?.name === null) throw new Error()

    return "videos/" + data.file
}

export const mapOverlayPathFromName = (name: string): string => {
    return "map-overlays/" + name + ".png"
}