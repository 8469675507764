import React from 'react'
import { PressableDiv, palette } from './Utils'
import { PageName, PushPageType } from './App'
import { popPage } from './historyUtils'


const ScreenWrapper: React.FC<{platform: 'desktop' | 'mobile', content: React.ReactElement, pushPage: PushPageType, noBack: boolean, darkMode: boolean}> = props => {
    const otherLogoNames = [
        'LOGO_ENI.png',
        'LOGO_MINISTERO CULTURA.png',
        'LOGO ARCHIDIOCESI.png',
        'LOGO COMMISARIO STRAORDINARIO.png',
        'LOGO_NORCIA.png',
        // 'LOGO_REGIONE UMBRIA.png',
    ]

    const paddingVert = {paddingLeft: '10%', paddingRight: '10%'}
    if (props.platform === 'mobile') {
        return props.content
    } else {
        return (
            <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', backgroundColor: props.darkMode ? 'black' : palette.primaryOpposite}}>
                <div style={{
                    flexDirection: 'row',
                    height: props.platform === 'desktop' ? '8%' : 0,
                    display: props.platform === 'desktop' ? 'flex' : 'none',
                    width: '100%',
                    backgroundColor: props.darkMode ? 'black' : palette.primary,
                    ...paddingVert,
                    boxSizing: 'border-box'
                }}>
                    <PressableDiv onClick={() => !props.noBack && popPage()} style={{height: '100%', flexDirection: 'row', paddingTop: '1vh', paddingBottom: '1vh', gap: '1vh'}}>
                        {!props.noBack && <img src="arrow_back.svg" style={{height: '100%'}} />}
                        <img src={props.darkMode ? "Logo_Norcia_white.png" : "Logo_Norcia.png"} style={{height: '100%'}} />
                    </PressableDiv>
                    <div style={{display: 'flex', flexDirection: 'row', paddingTop: '1vh', paddingBottom: '1vh', marginLeft: 'auto'}}>
                        {!props.darkMode && otherLogoNames.map(n => <img src={'other-logos/' + n} alt={n} key={n} style={{width: '16vh', objectFit: 'contain'}} />)}
                    </div>
                </div>
                <div style={{
                    width: '100%',
                    height: props.platform === 'desktop' ? '92%' : '100%',
                    ...paddingVert,
                    boxSizing: 'border-box'
                }}>
                    {props.content}
                </div>
            </div>
        )
    }
}


export const PlatformSwitch: React.FC<{desktop: React.ReactElement, mobile: React.ReactElement, pushPage: PushPageType, noBack?: boolean, darkMode?: boolean}> = props => {
    type StateType = {width: number, height: number, platform: 'desktop' | 'mobile'}

    const calcCurrentView = (): StateType => {
        const w = window.innerWidth
        const h = window.innerHeight
        return {
            width: w,
            height: h,
            platform: h > w ? 'mobile' : 'desktop'
        }
    }

    const [currentView, setCurrentView] = React.useState<StateType>(calcCurrentView())

    React.useEffect(() => {
        const updateCurrentView = () => {
            setCurrentView(calcCurrentView())
        }

         window.addEventListener('resize', () => {
            updateCurrentView()
         })
    }, [])

    return (
        <div style={{display: 'flex', width: currentView.width, height: currentView.height, backgroundColor: palette.primaryOpposite}}>
            <ScreenWrapper noBack={!!props.noBack} darkMode={!!props.darkMode} platform={currentView.platform} pushPage={props.pushPage} content={props[currentView.platform]} />
        </div>
    )
}