import React, { CSSProperties } from 'react'
import { PageComponentProps, PageName, PageRouterEntry } from './App'
import { PressableDiv, palette } from './Utils'
import { SimpleVideoPageArgs } from './TutorialScreen'


const MainButtons: React.FC<{textSize: string, imageSize: string, pushPage: (page: PageRouterEntry) => void}> = props => {
    const rowStyle: CSSProperties = {display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 'calc(' + props.imageSize + ' * 0.1)', cursor: 'pointer', transition: '0.2s'}
    const rowHoveredStyle: CSSProperties = {color: palette.hovered}
    const tutorialPageArgs: SimpleVideoPageArgs = {videoUrl: "./Norcia_Tutorial.mp4"}
    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
            <PressableDiv style={rowStyle} hoveredStyle={rowHoveredStyle} onClick={() => props.pushPage({name: PageName.tutorial, args: tutorialPageArgs})}>
                <span style={{fontSize: props.textSize}}>Demo introduttiva</span>
                <img src='bottone_pietra_1.png' alt='pietra1' style={{width: props.imageSize, height: props.imageSize}} />
            </PressableDiv>
            <PressableDiv style={rowStyle} hoveredStyle={rowHoveredStyle} onClick={() => window.location.href = "3d/"}>
                <span style={{fontSize: props.textSize}}>Entra</span>
                <img src='bottone_pietra_2.png' alt='pietra2' style={{width: props.imageSize, height: props.imageSize, marginRight: props.imageSize}} />
            </PressableDiv>
            <PressableDiv style={rowStyle} hoveredStyle={rowHoveredStyle} onClick={() => props.pushPage({name: PageName.videos, args: null})}>
                <span style={{fontSize: props.textSize}}>Vai alla mappa</span>
                <img src='bottone_pietra_3.png' alt='pietra3' style={{width: props.imageSize, height: props.imageSize}} />
            </PressableDiv>
        </div>
    )
}


export const MainMenuMobile: React.FC<PageComponentProps> = props => {
    return <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
        <div style={{display: 'flex', flexDirection: 'column', width: '100vw', alignItems: 'center', gap: '12vh'}}>
            <img src="Logo_Norcia.png" alt="Norcia Live Stones" style={{width: '45vh'}} />
            <MainButtons textSize='4vh' imageSize='10vh' pushPage={props.pushPage} />
        </div>
    </div>
}


export const MainMenuDesktop: React.FC<PageComponentProps> = props => {
    return <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
        <div style={{display: 'flex', position: 'relative', flexDirection: 'row', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'flex-start', gap: 0}}>
            <img src="Logo_Norcia.png" alt="Norcia Live Stones" style={{height: '60vh', marginRight: 'auto'}} />
            <MainButtons textSize='5vh' imageSize='20vh' pushPage={props.pushPage} />
        </div>
    </div>
}