import React, { CSSProperties } from 'react'


export const baseButtonStyle: CSSProperties = {color: 'white', fontSize: '3vh', borderStyle: 'none', width: '35vh', height: '8vh', cursor: 'pointer'}
export const primaryButtonStyle: CSSProperties = {...baseButtonStyle, backgroundImage: 'linear-gradient(70deg, #fbeb22, #c57b78, #c57b78)'}
export const secondaryButtonStyle: CSSProperties = {...baseButtonStyle, backgroundColor: '#c57b78'}

export const palette = {
    primary: '#edecea',
    primaryOpposite: 'white',
    hovered: 'orange',
    subtitle: '#bbb2a9',
}


export const PressableDiv: React.FC<{children: any, onMouseEnter?: () => void, onMouseLeave?: () => void, onClick: () => void, style: CSSProperties, hoveredStyle?: CSSProperties}> = props => {
    const [hovered, setHovered] = React.useState(false)
    return (
        <div
            onMouseEnter={() => {
                setHovered(true)
                props.onMouseEnter?.()
            }}
            onMouseLeave={() => {
                setHovered(false)
                props.onMouseLeave?.()
            }}
            onClick={props.onClick}
            style={{display: 'flex', cursor: 'pointer', boxSizing: 'border-box', ...props.style, ...(hovered ? props.hoveredStyle : null)}}
        >
            {props.children}
        </div>
    )
}